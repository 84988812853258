<!--
 * @Date: 2021-12-20 14:36:54
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\views\tongji.vue
-->
<template>
    <div class="bdy">
        <div class="bdy-title">出刊统计（半年内）</div>
        <div class="bdy-wk">
            <div class="bdy-left" v-loading="lei_loading">
                <div
                    @click="changePage(index)"
                    class="menu-item"
                    :class="[pos==index?'hover':'']"
                    v-for="(item,index) in leiMuList"
                    :key="index"
                >
                    <div style="width:100%;text-align:left">{{item[0]}}</div>
                    <div class="menu-item-desc">
                        <span>期刊本数</span>
                        <span>{{item[2]}}本</span>
                    </div>
                </div>
                
            </div>
            <div class="bdy-right" v-loading="list_loading">
                 <div v-if="list.length==0"  style="display:flex;justify-content:center;align-items:center;width:100%;margin:auto">
                        <el-empty description="数据为空~"></el-empty>
                 </div>
                  
                <div class="bdy-right-item" v-for="(item,index) in list" :class="[(index+1)%2==0?'on':'']" :key="index" >
                    <listItem :hdata="item" :showDown="false"></listItem>
                </div>
                
            </div>
        </div>
        <div style="height:100px"></div>
    </div>
</template>

<script>
import listItem from "../components/listItem.vue";
export default {
    name: 'feedback',
    components:{listItem},
    data() {
        return {
            pos: 0,
            rules: {
                content: [{ required: true, min: 6, message: '至少，不少于6个字符', trigger: 'change' }]
            },
            form: {
                content: "",
                image: [],
            },
            
            leiMuList:[],
            lei_loading:false,

            list:[],
            list_loading:false,
        }
    },
    mounted(){
        this.gettongjileimu();
    },
    methods: {
        changePage(pos) {
            if(this.list_loading||this.lei_loading) return;
            if(pos==this.pos) return;
            this.pos = pos;
            this.list=[];
            this.gettongjiList();
        },
        gettongjileimu(){
            let t = this;
            t.lei_loading=true;
            this.$api.gettongjileimu().then(d=>{
               setTimeout(() => {
                  t.lei_loading=false; 
                  t.leiMuList = [...d];
                  t.gettongjiList();
               }, 500);
            })
        },
        gettongjiList(){
            if(this.leiMuList.length<=0) return;
            let t = this;
            t.list_loading=true;
            t.lei_loading=true;
            this.$api.gettongjiList(this.leiMuList[this.pos][0]).then(d=>{
               setTimeout(() => {
                  t.list_loading=false; 
                   t.lei_loading=false;
                  if(d.data.length>0){
                       t.list = [...d.data];
                  }
                 
               }, 500);
            })
        }
    }
}
</script>

<style scoped lang="scss">
.bdy {
    width: 100%;
    .bdy-title{
        text-align: left;
        font-size: 28px;
        max-width: 1250px;
        padding: 0 50px;
        margin: auto;
        margin-top: 50px;
    }
    .bdy-wk {
        max-width: 1250px;
        padding: 0 50px;
        margin: auto;
        display: flex;
        margin-top: 24px;
        .bdy-right {
            background-color: rgb(250, 250, 250);
            padding: 32px 24px;
            width: 100%;
            border-radius: 26px;
            text-align: left;
            overflow-y: auto;
            height: 674px;
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            .bdy-right-item{
                margin-bottom: 24px;
                width: calc(50% - 24px);
                
                &.on{
                    margin-left: 24px;
                }
            }
        }
        .bdy-left {
            width: 320px;
            margin-right: 30px;
            background-color: rgb(250, 250, 250);
            border-radius: 26px;
            padding: 32px 24px;
            .menu-item {
                padding: 12px 24px;
                font-size: 16px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 12px;
                transition: background 0.5s;
                flex-flow: column;
                border-radius: 10px;
                background: white;
                padding: 24px 24px;
                .menu-item-desc{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    font-size: 14px;
                    margin-top: 12px;
                }
                &:hover,
                &.hover {
                    background: #01a1c4;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>